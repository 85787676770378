var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"close-on-click-modal":false,"width":"600px"},on:{"close":_vm.handleCancel}},[_c('el-table',{ref:"gridTable",attrs:{"data":_vm.tableData,"border":"","height":"400","header-cell-style":{
                        'background-color': '#E5EEF6',
                        'border-color': 'rgba(218, 223, 228, 1)',
                        height: '48px',
                      }}},[_c('el-table-column',{attrs:{"prop":"ItemNo","label":"货号","header-align":"center","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_vm._v(" "+_vm._s(row.ItemNo)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"SpecName","label":"规格","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_vm._v(" "+_vm._s(row.SpecName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"PickingNum","header-align":"center","align":"center","label":"拿货数量","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(row.PickingNum))])]}}])}),_c('el-table-column',{attrs:{"prop":"RefundNum","header-align":"center","align":"center","label":"退货数量","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(row.RefundNum))])]}}])})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.handleCancel}},[_vm._v("关闭")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }