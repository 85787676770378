<template>
    <el-dialog :title="title"
               :visible="visible"
               :close-on-click-modal="false"
               width="600px"
               @close="handleCancel">

        <el-table ref="gridTable" :data="tableData" border height="400" :header-cell-style="{
                            'background-color': '#E5EEF6',
                            'border-color': 'rgba(218, 223, 228, 1)',
                            height: '48px',
                          }">
            <el-table-column prop="ItemNo"
                             label="货号"
                             header-align="center" align="center" width="120">
                <template slot-scope="{ row }">
                    {{row.ItemNo}}
                </template>
            </el-table-column>
            <el-table-column prop="SpecName"
                             label="规格"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.SpecName}}
                </template>
            </el-table-column>
            <el-table-column prop="PickingNum"
                             header-align="center" align="center"
                             label="拿货数量" width="80">
                <template slot-scope="{ row }">
                    <span style="color:green;">{{row.PickingNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="RefundNum"
                             header-align="center" align="center"
                             label="退货数量" width="80">
                <template slot-scope="{ row }">
                    <span style="color:red;">{{row.RefundNum}}</span>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="handleCancel">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { submit } from "@/api/base";

    export default {
        name: "ProductReportDetail",
        data() {
            return {
                title: "",
                visible: false,
                tableData: [],
            };
        },
        methods: {
            init(data) {
                this.visible = true;
                this.title = "查看详情";
                var tableData = [];
                for (var j in data.PickingDetails) {
                    tableData.push({
                        SpecName: j,
                        ItemNo: data.ItemNo,
                        PickingNum: data.PickingDetails[j],
                        RefundNum:0,
                    });
                }
                for (var i in data.RefundDetails) {
                    var hasSpec = false;
                    tableData.forEach((item) => {
                        if (item.SpecName == i) {
                            hasSpec = true;
                            item.RefundNum = item.RefundNum + data.RefundDetails[i];
                        }
                    });
                    if (hasSpec == false) {
                        tableData.push({
                            SpecName: i,
                            ItemNo: data.ItemNo,
                            PickingNum: 0,
                            RefundNum: data.RefundDetails[i],
                        });
                    }
                }
                this.tableData = tableData;
            },
            handleCancel() {
                this.tableData = [];
                this.visible = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .dialog-footer {
        position: relative;

        .checkbox-block {
            position: absolute;
            left: 28px;
            top: 8px;
        }
    }
</style>